<template>
  <ResourceDialog
    :allowDelete="$store.role === 0"
    :allowModify="true"
    ref="dialog"
    v-on="$listeners"
    :rules="rules"
    entity-name="Presa"
    collection-name="printers"
  >
    <template slot-scope="{ data }">
      <!-- name field -->
      <ElFormItem label="Nume" prop="name">
        <ElInput v-model="data.name" />
      </ElFormItem>
      <!-- min field -->
      <ElFormItem label="Dimensiuni Minime">
        <div class="flex">
          <ElInput v-model.number="data.minWidth" />
          <ElInput v-model.number="data.minHeight" />
        </div>
      </ElFormItem>
      <!-- max field -->
      <ElFormItem label="Dimensiuni Maxime">
        <div class="flex">
          <ElInput v-model.number="data.maxWidth" />
          <ElInput v-model.number="data.maxHeight" />
        </div>
      </ElFormItem>
      <!-- margins field -->
      <ElFormItem label="Margini">
        <div class="flex">
          <ElInput v-model.number="data.marginTop" />
          <ElInput v-model.number="data.marginBottom" />
          <ElInput v-model.number="data.marginLeft" />
          <ElInput v-model.number="data.marginRight" />
        </div>
      </ElFormItem>
      <!-- colors field -->
      <ElFormItem label="Culori">
        <ElInput v-model.number="data.colors" />
      </ElFormItem>
      <!-- printingOperationId field -->
      <ElFormItem label="Printing Operation ID">
        <ElInput :disabled="$store.role !== 0" v-model.number="data.printingOperationId" />
      </ElFormItem>
      <!-- plateOperationId field -->
      <ElFormItem label="Plate Operation ID">
        <ElInput :disabled="$store.role !== 0" v-model.number="data.plateOperationId" />
      </ElFormItem>
      <!-- plateMaterialId field -->
      <ElFormItem label="Plate Material ID">
        <ElInput :disabled="$store.role !== 0" v-model.number="data.plateMaterialId" />
      </ElFormItem>
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialogMixin from "@/mixins/ResourceDialogMixin";

export default {
  mixins: [ResourceDialogMixin],
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: "Numele este obligatoriu",
            trigger: "blur"
          }
        ]
      };
    }
  }
};
</script>
