<template>
  <div>
    <Navigation />
    <div class="pa-5 flex space-between">
      <PrinterFilters />
      <ElButton @click="$refs.printerDialog.add()" type="primary"
        >Adauga Presa</ElButton
      >
    </div>
    <!-- table -->
    <ResourceTable
      :query="query"
      :filter="filter"
      :default-sort="{ prop: 'data.name' }"
    >
      <ElTableColumn type="index" width="50" />
      <ElTableColumn prop="data.name" label="Nume" sortable />
      <ElTableColumn align="right">
        <template slot-scope="{ row }">
          <ElButton @click="$refs.printerDialog.edit(row.id)" size="mini"
            >Modifica</ElButton
          >
        </template>
      </ElTableColumn>
    </ResourceTable>
    <!-- dialogs -->
    <PrinterDialog ref="printerDialog" />
  </div>
</template>

<script>
import FiltersMixin from "@/mixins/FiltersMixin";
import PrinterDialog from "@/components/printers/PrinterDialog";
import PrinterFilters from "@/components/printers/PrinterFilters";

export default {
  mixins: [FiltersMixin()],
  components: {
    PrinterDialog,
    PrinterFilters
  },
  computed: {
    query() {
      return this.$firestore().collection("printers");
    }
  },
  methods: {
    filter(row) {
      const { filters } = this;
      if (filters.name) {
        if (row.data.name.match(new RegExp(filters.name, "i")) === null) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>
